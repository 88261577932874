<template>
    <div class="popup_overlay">
    <div class="custom_dialog" :style="`width: 100%; overflow: auto; height:100vh;`">
      <div class="">
        <div>
          <div class="bg-primary flex justify-between p-2.5">
            <div class=" font-medium text-white heading-3 pl-1">
                {{fileName ? fileName : 'Attachment'}}
            </div>
            <div class="flex items-center">
              <div class=" text-primary heading-3 cursor-pointer bg-white px-2.5 py-1 rounded-xl ml-3" @click="discard(false)">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="flex items-center mb-4 image-show-container" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`">
            <div class=" font-medium text-text2 heading-4 pl-1 ">
              <div class="loader ease-linear ml-2"></div>
            </div>
          </div>
          <div v-if="loading">
            <div v-if="fileType === 'image' && !isSHowErr" class="p-2">
              <img :style="`width: auto;max-height: 100%;height:` + imageDivHeight2 + `px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);`" class="" :src="srcUrl" alt="">
            </div>
            <div v-if="fileType === 'video' && !isSHowErr" class="flex items-center mb-4">
              <video id="myVideo" autoplay class="mt-1" controls :style="`width: 100%;height:` + imageDivHeight2 + `px;`">
              <source  type="video/mp4" align="center" justify="center" class="ma-0" :src="srcUrl">
              </video>
            </div>
            <div v-if="fileType === 'pdf' && !isSHowErr" class="p-2" type="application/pdf">
              <iframe class="pdfIframe" :src="srcUrl" :style="`width: 100%;height:`+ imageDivHeight3 + `px;`" />
            </div>
            <div v-if="(fileType !== 'image' && fileType !== 'video' && fileType !== 'pdf') || isSHowErr" class="flex items-center mb-4 image-show-container justify-center" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`">
              <div class=" font-medium text-text2 heading-4 pl-1 ">
                  No Preview Available
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import * as config from '@/config.js'
import {downloadFile, checkFileTypeForPreview} from '@/utils/common.js'
import axios from 'axios'
export default {
  components: {
  },
  data () {
    return {
      loading: false,
      isSHowErr: false,
      deleteAttch: false,
      baseUrl: config.API_ROOT,
      srcUrl: '',
      imageDivHeight3: window.innerHeight - 80,
      imageDivHeight: window.innerHeight ,
      imageDivHeight2: window.innerHeight - 140
    }
  },
  props: ["fileType", "fileVaultId", "fileName"],
   created() {
  },
  mounted () {
    document.title = 'Full Screen Image'
    this.$root.$on('confirmBoxHandler', (response) => {
      if (response) {
        this.discard(true)
      }
      document.body.style = 'overflow: visible;'
      this.deleteAttch = false
    })
    this.downLoadDocument()
    // this.srcUrl = this.baseUrl + '/api/v1/notes/getImageWeb' + '?saToken=' + getCookies('jobAuthToken') + '&fileVaultId=' + this.fileVaultId
    console.log('srcUrl', this.srcUrl);
    document.body.style = 'overflow: hidden;'
  },
  watch: {},
  methods: {
    discard (data) {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('fullImageHandler', data)
    },
    async downLoadDocument () {
        let url = this.baseUrl + 'api/v1/superadmin/organization/downloadAttachment'
        let formData = new FormData()
        formData.append('fileVaultId', this.fileVaultId)
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        this.$store.dispatch('CancelDownloadRequest', source)
        let response = await downloadFile(formData, url, source)
        if (response.status === 200) {
          this.$store.dispatch('CancelDownloadRequest', null)
          if (checkFileTypeForPreview(response.headers['content-type'])) {
            this.srcUrl = URL.createObjectURL(response.data)
          }
          this.$store.dispatch('SetDownloadLoader', false)
          this.$store.dispatch('SetDownloadMessage', '')
          this.loading = true
        } else {
          this.isSHowErr = true
          this.loading = true
          this.$store.dispatch('CancelDownloadRequest', null)
          this.$store.dispatch('SetDownloadMessage', '')
          this.$store.dispatch('SetDownloadLoader', false)
        }
      },
  },
  beforeDestroy () {
    this.$root.$off("confirmBoxHandler");
  }
}
</script>
<style scoped>
.imageFullScreen {
  width: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}
.image-show-container {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
</style>
<template>
  <div class="">
    <div class=" flex justify-between">
    <p class="text-text1 font-bold mb-4 heading-4 ">{{mainTitle}}</p>
    </div>
    <div v-if="noteLIst.length > 0">
        <div class="mb-4 text-text1 note_icn_box " v-for="(note,i) in noteLIst" :key="i">
            <div class="flex flex-wrap justify-between mb-2">
                <div class=""> <span class="text-gray4 heading-6">Added By: </span> 
                    <span class="">{{note.firstName.charAt(0).toUpperCase() + note.firstName.slice(1)}} </span>
                    <span class="">{{note.lastName.charAt(0).toUpperCase() + note.lastName.slice(1)}}</span>
                </div>
                <div class="flex items-center gap-2">
                  <span class="text-gray4 heading-6">{{note.addedDate | dateManyAgo }}</span>
                </div>
            </div>
            <div class="mb-2">
                <!-- <div class="">
                    <span class="text-gray4 heading-6">Note: </span>
                    <span v-if="note.notes !== ''"> {{note.notes.substr(0,note.charLimit)}} <span class="text-primary heading-7 cursor-pointer" @click="noteLIst[i].charLimit = note.notes.length" v-if="note.notes.length > 120 && note.charLimit === 120">... Read more</span></span>
                    <span v-else>-</span>
                </div> -->
                <div>
                  <span class="text-gray4 heading-6">Note: </span>
                  <span class="mb-0 whitespace-pre-line">
                    {{note.notes === '' ? '-' : (note.notes.length > 125)? note.readMoreNote ?  note.notes : note.notes.substr(0, 125) : note.notes}}
                    <span v-if="note.notes.length > 125" class="text-primary cursor-pointer heading-7" @click="note.readMoreNote = !note.readMoreNote">
                      {{note.readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
                  </span>
                </div>
            </div>
            <div v-if="note.attachmentList.length > 0" class="">
               <p class="text-primary heading-6 mb-2">Attachments:</p>
               <div class="flex gap-4" style="flex-wrap: wrap;">
                <div class=" card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer group" style="width: 120px; height: 80px"  v-for="(attach, a) in note.attachmentList " :key="a" @click="showFullImage(attach)">
                    
                    <div v-if="attach.docType === 'image'" class="text-center">
                        <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                        <div class="invisible group-hover:visible absolute bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                        <img style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                        <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                        <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                        <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                    </div>
                    <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                        <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                        <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                    </div>
                  </div>
               </div>
            </div>
            <div v-if="i + 1 !== noteLIst.length" class="divider my-4"></div>
        </div>
    </div> 
    <div v-else>
        <NoRecords :alertMessage="` No Notes found`" />
    </div>
    <FullImage v-if="shoFullImage" :fileVaultId="fileVaultId" :fileType="fileType" :fileName="fileName" />  
    <AddUpdateNote v-if="addUpdateNote" :pathPrefix="pathPrefix" :requessType="requestType" :updateNoteObj="updateNoteObj" :reqestId="detailId" />  
    <div v-if="isdeleteNoteConfirm" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="isdeleteNoteConfirm = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteNotes"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import FullImage from './FullImage.vue'
import NoRecords from '@/View/components/noRecords.vue'
// import NoRecords from '@/View/components/noRecords.vue'
export default {
  name: "lead",
  components: {
    Button,
    FullImage,
    NoRecords,
    // NoRecords,
  },
  data() {
    return {
        mainTitle: '',
        noteLIst: [],
        pathPrefix: '',
        fileVaultId: 0,
        fileType: '',
        fileName: '',
        shoFullImage: false,
        addUpdateNote: false,
        updateNoteObj: null,
        isdeleteNoteConfirm: false,
        noteId: 0,
    };
  },
  props: ['requestType', 'detailId', 'noteTitle'],
  created() {},
  mounted() {
    document.title = 'Note List'
    if (this.noteTitle) {
      this.mainTitle  = this.noteTitle
    } else {
      this.mainTitle = 'Note List'
    }
    this.getNotelist()
    this.$root.$on('fullImageHandler', (response) => {
        if (response) {
          this.deleteAttach()
        }
        this.shoFullImage = false
    })
    this.$root.$on('addNoteHandler', (response) => {
      document.body.style = 'overflow: visible;'
        if (response) {
            this.getNotelist()
        }
            this.addUpdateNote = false
    })
  },
  methods: {
    addNewNote () {
      this.updateNoteObj = null
      this.noteId = 0
      this.addUpdateNote = true
    },
    
    deleteNotes () {
      this.isdeleteNoteConfirm = false
      this.deletesNote()
    },
    updateNote (noteId, data) {
      // this.updateNoteObj = data
      this.updateNoteObj = JSON.parse(JSON.stringify(data))
      this.addUpdateNote = true
      this.noteId = noteId
    },
    
    deleteConfNote (noteId) {
      this.isdeleteNoteConfirm = true
      this.noteId = noteId
    },
    showFullImage (data) {
        this.fileVaultId = data.fileVaultId
        this.fileType = data.docType
        this.fileName = data.displayFileName
        this.shoFullImage = true
    },
    getNotelist () {
    console.log('TEST')
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    ADMINAPI.getNotesLIst(
      parseInt(this.$route.params.orgId),
        this.requestType,
        this.detailId,
        0,
        5,
        '',
          response => {
            this.updateNoteObj = null
            this.totalVisitCount = response.count
            this.pathPrefix = response.Data.pathPrefix
            let templist = []
            if (response.Data.noteList !== null) {
                response.Data.noteList.map(data=> {
                   let attachmentList = JSON.parse(data.attachments).attachmentList

                   let attach = []

                    if (attachmentList !== null) {
                        attachmentList.map(a => {
                            let temp = a.fileType.split('/')
                            console.log('temp', temp)
                            let type = ''
                            if (temp[0] === 'image' ) {
                                type = 'image'
                            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                              type = 'pdf'
                            } else if (temp[0] === 'video' ) {
                              type = 'video'
                            }
                            attach.push({...a, docType: type})
                        })
                    }

                    templist.push({...data, charLimit: 120, attachmentList: attach, readMoreNote: false})
                })      
            }
            this.noteLIst = templist
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
  },
  beforeDestroy() {
    this.$root.$off("fullImageHandler");
    this.$root.$off("confirmBoxHandler");
  },
};
</script>
<style scoped>
.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
}
.scroll-img-view{
  height: 104px;
}
/* .note_icn {
  visibility:hidden;
}
.note_icn_box:hover .note_icn {
  visibility: visible !important;
} */
.zIndex {
  z-index: 100 !important;
}
</style>